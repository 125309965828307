import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';
import './env.config'
import App from './App';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

ReactDOM.render (
  <GoogleReCaptchaProvider
    reCaptchaKey="6LcTJsInAAAAAJHJ3PNbLoPvx1tCP__3T8L11X9s"
  >
    <App />
  </GoogleReCaptchaProvider>,
  document.getElementById ('root')
);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(
      <NextApp />,
      document.getElementById('root')
    )
  })
}

serviceWorker.unregister();
